<template>
  <article
    class="content"
    style="margin-top: -50px;padding:10px;background-color: #221f1f"
    itemscope
    itemtype="http://schema.org/Event"
    v-if="!this.$store.state.status.loading"
  >
    <div class="header text-center " style="margin-bottom: 5px">
      <br />
      <br />
      <h1
        class="card-title tulum-party-title mt-0 mb-0"
        style="
          font-size: 1.9rem;
          font-weight: bold;
          text-transform: uppercase;
          color: #fff !important;
        "
        itemprop="name"
      >
        {{ currentEvent.title }}
      </h1>
      <p class="event-time-location" style="font-size: 14px;margin-top: 5px;padding: 2px;margin: 20px">
        <time
          itemprop="startDate"
          v-bind:content="currentEventDateNTime"
          v-bind:datetime="currentEventDateNTime"
          v-if="currentEventDateNTime"
        >
          {{ formatDate(currentEventDateNTime) }}
        </time>
        <span>{{ currentEventPlace }}</span>
        <span>
          , <span>{{ currentEventLocality }}</span>
        </span>
      </p>
      <br />

      <p
        style="
          font-family: 'Oswald', sans-serif;
          text-transform: capitalize;
          margin-top: -20px;
          font-size: 14px;
          margin-bottom: 10px;
          background-color: #1a1818;
        "
        v-if="currentEvent.metadata.caption"
      >
        {{ currentEvent.metadata.caption }}
      </p>
      <br />
      <ResponsiveImage :image="currentEventImage" itemprop="image"
                       wl="400"
                       hl="500"
                       ws="270"
                       hs="270"
                       :clamp="false"></ResponsiveImage>
      <br />
<!--      <EventBrand :title="currentEvent.title" style="margin: 10px"></EventBrand>-->
<!--      <EventVenue :title="currentEvent.title"></EventVenue>-->
      <br>

      <div v-if="isPastEvent" style="margin-top: 50px">

        <h4 class="tulum-party-subtitle " style="font-size: 0.9rem">
          ⚠️ This event occurred in the past
        </h4>
        <p class="text-center">
          Check our
          <router-link
            :to="{ name: 'Home' }">Event Line
          </router-link>
          or
          <router-link :to="{ name: 'PartyCalendar' }"> Calendar</router-link>
          for the full list of <b>upcoming events</b></p>
        <div class="text-center btn-group btn-group-vertical"
             style="
          margin: 10px;
          margin-top: 30px;
          width: 100%;
          max-width: 350px;
          background-color: #2f2c2c;
        "
        >

          <router-link
            v-if="isPastEvent"
            :to="{ name: 'Home' }"
            class="btn btn-primary">
            upcoming events
          </router-link>

        </div>
      </div>


      <div
        class="text-center btn-group btn-group-vertical"
        style="
          margin: 10px;
          margin-top: 20px;
          margin-bottom: 40px;
          width: 100%;
          max-width: 350px;
          background-color: #2f2c2c;
        "
      >
        <a
          v-if="currentEvent.metadata.link && !isPastEvent"
          v-bind:href="currentEvent.metadata.link"
          v-on:click="onTicketClick(currentEvent.title)"
          class="btn btn-primary"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          GET TICKETS
        </a>
      </div>
      <div
        class="card"
        style="padding: 10px;max-width: 500px;margin-left: auto;margin-right: auto;
          background-color: rgb(26, 24, 24);margin-top: -20px; "
        v-if="!currentEvent.metadata.link && !isPastEvent"
      >
        <h3 class="tulum-party-subtitle" style="margin-top: 20px">📲 INFO & RSVP</h3>
        <EventVenue :title="currentEvent.title" no-icon style="margin-top: -30px"></EventVenue>

        <p style="margin-top: 20px">
          <strong>Secure your spot.</strong>
          <br>Have questions or need more details? We're here to help.
        </p>
        <Reservations identifier="GeneralQuestion"></Reservations>
      </div>
    </div>
    <div class="card" style="padding: 20px">
      <div class="card-body">
        <h3
          class="section-header"
          style="text-transform: uppercase; font-size: 1.3rem;"
        >
          Event Details
        </h3>
        <hr />
        <div itemprop="description">
          <div v-html="currentEvent.content" class="card-text"></div>
        </div>


        <div style="margin-top: 5px" v-if="currentEvent.metadata.venue">
          <hr />
          <h3
            style=" font-size: 1.3rem"
          >


            LOCATION</h3>

<!--          <router-link-->
<!--            :to="{-->
<!--              name: 'Club',-->
<!--              params: { slug: currentEvent.metadata.venue.slug },-->
<!--            }"-->
<!--            class="btn-link btn-sm"-->
<!--            style="font-size: 0.8rem"-->
<!--          >-->
<!--            📍 {{ currentEvent.metadata.venue.title.toUpperCase() }}-->
<!--          </router-link>-->

          {{currentEvent}}
          <hr />
          <span
            itemprop="location"
            itemscope=""
            itemtype="http://schema.org/Place"
          >
<!--            <span itemprop="name">{{ currentEventPlace }}</span>-->
            <br />
<!--            <span-->
<!--              id="address-microdata"-->
<!--              style="font-size: 10px"-->
<!--              itemprop="address"-->
<!--              itemscope=""-->
<!--              itemtype="http://schema.org/PostalAddress"-->
<!--            >-->
<!--              {{ currentEventAddress }} ,-->
<!--              <span itemprop="addressLocality">{{ currentEventLocality }}</span>-->
<!--              <meta-->
<!--                itemref="address-microdata"-->
<!--                itemprop="addressCountry"-->
<!--                content="MX"-->
<!--              />-->
<!--            </span>-->
          </span>
          <br />
          <br />
        </div>
        <!--        <VIDEO-->
        <div class="text-center card" style="border: 1px solid black"
             v-if="currentEvent.metadata.video && currentEvent.metadata.video.metadata"
        >
          <hr />

          <!--        <PHOTOS-->
        </div>
      </div>

      <div
        class="text-center "
        style="margin-top: 30px; margin-bottom: 40px"
        v-if="currentEvent.metadata.tickets_link"
      >
        <a
          style="width: 80%"
          v-if="!isPastEvent"
          v-bind:href="currentEvent.metadata.tickets_link"
          v-on:click="onTicketClick(currentEvent.title)"
          rel="nofollow noopener noreferrer"
          target="_blank"
          class="btn btn-primary centered text-center"
        >
          TICKETS
        </a>
        <p v-if="isPastEvent">
          This event occurred in the past, see the list of the upcoming events
        </p>

        <router-link
          v-if="isPastEvent"
          :to="{ name: 'Home' }"
          class="btn btn-primary"
        >
          upcoming events
        </router-link>
      </div>
      <div
        class="card text-center"
        style="
          display: flex;
          justify-content: center;
          padding: 1px;
          margin-top: 30px;
          margin-bottom: 0;
        "
        v-if="currentEvent.metadata.reservations_link && !isPastEvent"
      >

        <EventReservations :slug="currentEvent.slug"></EventReservations>
        <br>
        <br>
        <EventSearch :incoming-parties="this.incomingParties"
                     style="position: fixed;bottom: -10px;z-index: 10" v-if="this.partiesLoaded"></EventSearch>
      </div>
    </div>
  </article>
</template>

<script>
import Reservations from '../../components/Reservations/Reservations';
import HlsVideoPlayer from '@/components/HlsVideoPlayer/HlsVideoPlayer';
import moment from 'moment';
import { mapState } from 'vuex';
import EventSearch from '@/components/EventSearch';
import EventBrand from '@/components/EventCard/EventBrand';
import EventVenue from '@/components/EventCard/EventVenue';
import EventReservations from '@/components/EventCard/EventReservations';
import { venueDecoder } from '@/common/Decoder';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import { slugUtils } from '@/common/slugUtils';

export default {
  name: 'Event',
  props: ['slug'],
  mixins: [slugUtils],
  metaInfo() {
    const eventTitle = this.getEventTitleFromSlug(this.slug);
    // const ArtistName = this.slug.replace(/-/g, ' ').toUpperCase();

    return {
      title: `[tulum.party] ${eventTitle}`,
      // link: eventImage,
      meta: [
        {
          name: 'description',
          content: `Event Info, Tickets & Reservations. ${this.currentEventCaption}. ${this.cleanContent}`
        },
        {
          name: 'keywords',
          content: `Tulum, tickets, party, information, reservations, bookings, ${eventTitle}, club, nightclub`
        },
        {
          property: 'og:title',
          content: `${this.currentEvent.title}`
        },
        {
          property: 'og:url',
          content: `https://tulum.party/event/${this.slug}`
        },
        {
          property: 'og:site_name',
          content: `TULUM PARTY`
        },
        {
          property: 'og:description',
          content: `Event Tickets & Reservations. ${this.currentEventCaption}`
        },
        { property: 'og:type', content: 'article' },
        {
          property: 'og:image',
          content: this.currentEventImage
        }
      ]
    };
  },
  created() {
    this.$store.dispatch('getMiniParty', { slug: this.slug }).then(response => {
    });

  },
  data: () => {
    return {
      table: [],
      enlargeImg: false,
      showReservations: false
    };
  },
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd';
      return moment(partyDate).calendar({ format: format });
    }
  },
  components: { ResponsiveImage, EventReservations, EventBrand, EventSearch, Reservations, HlsVideoPlayer, EventVenue },
  methods: {
    onTicketClick(event) {
      let value = 2;
      if (this.currentEvent.slug.match(/papaya|zero/i)) {
        value = 1;
      }
      if (this.currentEvent.slug.match(/bagatelle|bonbo/i)) {
        value = 5;
      }
      window.ga('send', 'event', 'Event', 'Tickets', this.currentEvent.slug, value);
      this.$rollbar.info(`Ticket link: ${event}`);
    },
    enlargeImage() {
      this.enlargeImg = !this.enlargeImg;
    },
    formatDate(date) {
      return moment(date).format('dddd, DD MMMM YYYY, hh:mma');
    }
  },
  computed: mapState({
    currentEvent: (state) => state.party,
    partiesLoaded: (state) => state.parties_loaded,
    incomingParties: (state) => state.incomingParties,
    currentEventCaption: (state) =>
      state.party.metadata.caption ? state.party.metadata.caption : '',
    currentEventImage: (state) =>
      state.party.metadata.image_url,
    currentEventPlace: (state) =>
      (venueDecoder(state.party.title).title + '').replace('Tulum', ''),
    currentEventLocality: (state) => {
      if (state.party.title && state.party.title.match(/Tulum/i)) {
        return 'Tulum';
      }
      if (state.party.title && state.party.title.match(/Cabo/i)) {
        return 'Cabo San Lucas';
      }
      if (state.party.title && state.party.title.match('Cancun')) {
        return 'Cancun';
      }
      if (state.party.title && state.party.title.match(/Oaxaca/i)) {
        return 'Oaxaca de Juarez';
      }
      if (state.party.title && state.party.title.match('Ciudad de M|Mexico City')) {
        return 'Ciudad de Mexico';
      }
      if (state.party.title && state.party.title.match(/Playa del Carmen/i)) {
        return 'Playa del Carmen';
      }
      return 'Tulum';
    },

    isPastEvent: (state) => {
      if (state.party.metadata && state.party.metadata.date) {
        return (
          moment.utc(state.party.metadata.date).endOf('day') <=
          moment().startOf('day')
        );
      }
    },
    currentEventDateNTime: (state) =>
      state.party.metadata.date &&
      state.party.metadata.date +
      'T' +
      state.party.metadata.start_time +
      '-05:00',
    cleanContent: (state) =>
      state.party.content &&
      state.party.content.replace(/(<([^>]+)>)/gi, '').substr(0, 200) +
      '&hellip;'
  })
};
</script>

